<template>
	<SectionCard>
		<template #title>
			Preference Configuration
		</template>
		<template #title-action>
			<TextButton
				v-if="userFullPermissions"
				@click="showAddGroupModal = {}"
			>
				<v-icon>
					mdi-plus
				</v-icon>
				Create Group
			</TextButton>
		</template>
		<template #body>
			<DataSubjectPreferenceExpansionPanels
				config-mode
				:preference-groups="parentGroups"
			>
				<template #header>
					<th
						class="text-left"
						style="calc(width:100% - 600px);"
					>
						{{ 'Channel' | useLabels }} Name
					</th>
					<th
						class="text-left"
						style="width:200px"
					>
						Can Be Added
					</th>
					<th
						class="text-left"
						style="width:200px"
					>
						Hide if no Preference Held
					</th>
					<th
						class="text-left"
					>
						Manage Channel
					</th>
				</template>
				<template
					v-if="userFullPermissions"
					#parent-group-header="{ parentGroupName, parentGroupIndex }"
				>
					<IconButton
						v-if="parentGroupIndex !== 0"
						small-icon
						color="white"
						@click.stop="moveParentGroupUp(parentGroupIndex)"
					>
						mdi-arrow-up
					</IconButton>
					<IconButton
						v-if="parentGroupIndex !== parentGroups.length - 1"
						small-icon
						color="white"
						@click.stop="moveParentGroupDown(parentGroupIndex)"
					>
						mdi-arrow-down
					</IconButton>
					<TextButton
						color="white"
						small
						@click.stop="showAddSubGroupModal = { parentGroupIndex, parentGroupName }"
					>
						<v-icon
							color="white"
							style="transform: unset;"
						>
							mdi-plus
						</v-icon>
						Create Subgroup
					</TextButton>
					<TextButton
						color="white"
						small
						@click.stop="showEditGroupModal = { parentGroupName, parentGroupIndex }"
					>
						<v-icon
							color="white"
							style="transform: unset;"
						>
							mdi-pencil
						</v-icon>
						Edit Group
					</TextButton>
					<TextButton
						color="white"
						small
						@click.stop="parentGroupToDelete = { parentGroupName, parentGroupIndex }"
					>
						<v-icon
							color="white"
							style="transform: unset;"
						>
							mdi-trash-can
						</v-icon>
						Delete Group
					</TextButton>
				</template>
				<template
					v-if="userFullPermissions"
					#sub-group-header="{ subGroupName, subGroupIndex, parentGroupIndex, parentGroupName, channels }"
				>
					<IconButton
						v-if="subGroupIndex !== 0"
						color="white"
						@click.stop="moveSubGroupUp(parentGroupIndex, subGroupIndex)"
					>
						mdi-arrow-up
					</IconButton>
					<IconButton
						v-if="subGroupIndex !== parentGroups[parentGroupIndex].subGroups.length - 1"
						color="white"
						@click.stop="moveSubGroupDown(parentGroupIndex, subGroupIndex)"
					>
						mdi-arrow-down
					</IconButton>
					<TextButton
						color="white"
						small
						@click.stop="showEditSubGroupModal = { subGroupName, subGroupIndex, parentGroupIndex, parentGroupName, channels }"
					>
						<v-icon
							color="white"
							style="transform: unset;"
						>
							mdi-pencil
						</v-icon>
						Edit Subgroup
					</TextButton>
					<TextButton
						color="white"
						small
						@click.stop="subGroupToDelete = { subGroupName, subGroupIndex, parentGroupIndex }"
					>
						<v-icon
							color="white"
							style="transform: unset;"
						>
							mdi-trash-can
						</v-icon>
						Delete Subgroup
					</TextButton>
				</template>
				<template #rows="{ channels, parentGroupIndex, subGroupIndex }">
					<tr
						v-for="(channel, channelIndex) in channels"
						:key="channel.channelId"
					>
						<td>
							{{ channel.channelName }}
						</td>
						<td>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="channel.canBeAdded"
								dense
								hide-details
								class="ma-0"
								@change="setChannelProperty(channelIndex, parentGroupIndex, subGroupIndex, 'canBeAdded', $event)"
							/>
						</td>
						<td>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="channel.hideNoPref"
								dense
								hide-details
								class="ma-0"
								@change="setChannelProperty(channelIndex, parentGroupIndex, subGroupIndex, 'hideNoPref', $event)"
							/>
						</td>
						<td>
							<IconButton
								v-if="userFullPermissions"
								tooltip-text="Manage Extended Preferences and Required StandDak Fields"
								@click.stop="showManageChannelModal = { parentGroupIndex, subGroupIndex, channel, channelIndex }"
							>
								mdi-pencil
							</IconButton>
						</td>
					</tr>
				</template>
			</DataSubjectPreferenceExpansionPanels>
			<CsPortalConfigPreferencesParentGroupModal
				v-if="showAddGroupModal || showEditGroupModal"
				v-bind="showEditGroupModal"
				@close="() => {
					showAddGroupModal = null
					showEditGroupModal = null
				}"
				@add="addGroup"
				@edit="editGroup"
			/>
			<!-- remove available fields prop below -->
			<CsPortalConfigPreferencesSubGroupModal
				v-if="showEditSubGroupModal || showAddSubGroupModal"
				v-bind="showEditSubGroupModal || showAddSubGroupModal"
				:all-selected-channels="allSelectedChannels"
				:move="move"
				@close="() => {
					showEditSubGroupModal = null
					showAddSubGroupModal = null
				}"
				@add="addSubGroup"
				@edit="editSubGroup"
			/>
			<CsPortalConfigPreferencesChannelModal
				v-if="showManageChannelModal"
				v-bind="showManageChannelModal"
				:available-fields="availableFields"
				@close="() => {
					showManageChannelModal = null
				}"
				@edit="editChannel"
			/>
			<ConfirmDeleteModal
				v-if="parentGroupToDelete"
				:entity-name="parentGroupToDelete.parentGroupName"
				entity-type="Group"
				@close="parentGroupToDelete = null"
				@delete="deleteGroup"
			/>
			<ConfirmDeleteModal
				v-if="subGroupToDelete"
				:entity-name="subGroupToDelete.subGroupName"
				entity-type="Subgroup"
				@close="subGroupToDelete = null"
				@delete="deleteSubGroup"
			/>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextButton from '../../../../../../shared/components/text-button.vue'
import DataSubjectPreferenceExpansionPanels from '../../../../../../shared/components/data-subjects/data-subject-preference-expansion-panels.vue'
import CsPortalConfigPreferencesParentGroupModal from './cs-portal-config-preferences-parent-group-modal.vue'
import CsPortalConfigPreferencesSubGroupModal from './cs-portal-config-preferences-sub-group-modal.vue'
import CsPortalConfigPreferencesChannelModal from './cs-portal-config-preferences-channel-modal.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'

const clone = data => JSON.parse(JSON.stringify(data))

export default {
	components: {
		IconButton,
		ConfirmDeleteModal,
		CsPortalConfigPreferencesSubGroupModal,
		CsPortalConfigPreferencesParentGroupModal,
		CsPortalConfigPreferencesChannelModal,
		DataSubjectPreferenceExpansionPanels,
		TextButton,
		SectionCard
	},
	props: {
		parentGroups: {
			type: Array,
			default: () => []
		},
		availableFields: {
			type: Array,
			default: () => []
		},
		userFullPermissions: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			showAddGroupModal: null,
			showEditGroupModal: null,
			showAddSubGroupModal: null,
			showEditSubGroupModal: null,
			showManageChannelModal: false,
			channelToManage: null,
			parentGroupToDelete: null,
			subGroupToDelete: null
		}
	},
	computed: {
		allSelectedChannels () {
			const allChannels = clone(this.parentGroups).map(parentGroup => {
				return parentGroup.subGroups.map(subgroup => {
					return subgroup.channels
				}).flat()
			}).flat()
			return allChannels
		}
	},
	methods: {
		move (array, from, to) {
			const arrayCopy = clone(array)
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return arrayCopy
		},
		moveParentGroupUp (parentGroupIndex) {
			this.updateParentGroups(this.move(this.parentGroups, parentGroupIndex, parentGroupIndex - 1))
		},
		moveParentGroupDown (parentGroupIndex) {
			this.updateParentGroups(this.move(this.parentGroups, parentGroupIndex, parentGroupIndex + 1))
		},
		moveSubGroupUp (parentGroupIndex, subGroupIndex) {
			const parentGroups = clone(this.parentGroups)
			const parentGroup = parentGroups[parentGroupIndex]
			parentGroup.subGroups = this.move(parentGroup.subGroups, subGroupIndex, subGroupIndex - 1)
			this.updateParentGroups(parentGroups)
		},
		moveSubGroupDown (parentGroupIndex, subGroupIndex) {
			const parentGroups = clone(this.parentGroups)
			const parentGroup = parentGroups[parentGroupIndex]
			parentGroup.subGroups = this.move(parentGroup.subGroups, subGroupIndex, subGroupIndex + 1)
			this.updateParentGroups(parentGroups)
		},
		updateParentGroups (parentGroups) {
			this.$emit('update:parentGroups', parentGroups)
		},
		addGroup (parentGroupName) {
			if (this.parentGroups) {
				this.updateParentGroups([
					...clone(this.parentGroups),
					{
						parentGroupName,
						subGroups: [],
						parentGroupId: 0,
						displayOrder: this.parentGroups.length + 1,
						expandedByDefault: true
					}
				])
			} else {
				this.updateParentGroups([
					{
						parentGroupName,
						subGroups: [],
						parentGroupId: 0,
						displayOrder: 1,
						expandedByDefault: true
					}
				])
			}
		},
		editGroup (parentGroupName, parentGroupIndex) {
			const parentGroups = clone(this.parentGroups)
			const parentGroup = parentGroups[parentGroupIndex]
			parentGroup.parentGroupName = parentGroupName
			this.updateParentGroups(parentGroups)
		},
		deleteGroup () {
			this.updateParentGroups(this.parentGroups.filter((_, index) => index !== this.parentGroupToDelete.parentGroupIndex))
			this.parentGroupToDelete = null
		},
		addSubGroup (parentGroupIndex, subGroupName, channels) {
			const parentGroups = JSON.parse(JSON.stringify(this.parentGroups))
			const parentGroup = parentGroups[parentGroupIndex]
			parentGroup.subGroups = [...parentGroup.subGroups, {
				canBeAdded: true,
				channels,
				// colour: '#3498db',
				display: true,
				displayOrder: parentGroup.subGroups.length + 1,
				expandedByDefault: true,
				// hasHoliday: false,
				hideNoPref: false,
				parentGroupId: parentGroup.parentGroupId,
				subGroupId: 0,
				subGroupName
			}]
			this.updateParentGroups(parentGroups)
		},
		editSubGroup (parentGroupIndex, subGroupName, channels, subGroupIndex) {
			const parentGroups = clone(this.parentGroups)
			const parentGroup = parentGroups[parentGroupIndex]
			parentGroup.subGroups[subGroupIndex].subGroupName = subGroupName
			parentGroup.subGroups[subGroupIndex].channels = channels
			this.updateParentGroups(parentGroups)
		},
		editChannel (parentGroupIndex, subGroupIndex, channel, channelIndex) {
			const parentGroups = clone(this.parentGroups)
			parentGroups[parentGroupIndex].subGroups[subGroupIndex].channels[channelIndex] = channel
			this.updateParentGroups(parentGroups)
			this.showManageChannelModal = null
		},
		deleteSubGroup () {
			const parentGroups = clone(this.parentGroups)
			const parentGroup = parentGroups[this.subGroupToDelete.parentGroupIndex]
			parentGroup.subGroups = parentGroup.subGroups.filter((_, index) => index !== this.subGroupToDelete.subGroupIndex)
			this.updateParentGroups(parentGroups)
			this.subGroupToDelete = null
		},
		setChannelProperty (channelIndex, parentGroupIndex, subGroupIndex, property, value) {
			const parentGroups = clone(this.parentGroups)
			parentGroups[parentGroupIndex].subGroups[subGroupIndex].channels[channelIndex][property] = value

			const displayToggleName = 'display'
			const isDisplay = parentGroups[parentGroupIndex].subGroups[subGroupIndex].channels[channelIndex][displayToggleName]
			if (isDisplay === false && value === true) {
				parentGroups[parentGroupIndex].subGroups[subGroupIndex].channels[channelIndex][displayToggleName] = true
			}

			this.updateParentGroups(parentGroups)
		}
	}
}
</script>
