<template>
	<Modal width="900px">
		<template #modal-title>
			{{ modalTitle }}
		</template>
		<template #modal-content>
			<div class="cassie-vertical-md">
				<div class="d-flex flex-row cassie-horizontal-sm">
					<Dropdown
						v-model="selectedFields"
						label="Required StandDak Fields"
						:items="availableStandDakFields"
						multiple
						persistent-hint
						class="cassie-input-width-md"
					/>
					<PrimaryActionButton @click="addFields">
						Add
					</PrimaryActionButton>
				</div>
				<v-simple-table>
					<template #default>
						<thead>
							<tr>
								<th
									scope="col"
									class="text-left"
								>
									Field Name
								</th>
								<th
									scope="col"
									class="text-right"
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(field, index) in channelToEdit.requiredStandDakFields"
								:key="field"
							>
								<td>
									{{ field }}
								</td>
								<td class="text-right">
									<v-icon
										small
										@click="removeStandDakField(index)"
									>
										mdi-trash-can
									</v-icon>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<TextButton
					v-if="!editExtendedPreference"
					@click="showExtendedPreferenceCard = !showExtendedPreferenceCard"
				>
					Add Extended Preference
				</TextButton>
				<div
					v-if="showExtendedPreferenceCard"
					class="cassie-vertical-md"
				>
					<Dropdown
						v-model="extendedPreference.keyId"
						:disabled="editExtendedPreference"
						label="Extended Preference*"
						:items="availableExtendedPrefs"
						rules="required"
						no-data-text="No extended preferences available to select"
						return-object
					/>
					<Dropdown
						v-model="extendedPreference.fieldTypeId"
						label="Field Type*"
						:items="fieldTypeItems"
						:disabled="!extendedPreference.keyId"
						rules="required"
					/>
					<TextField
						v-model="extendedPreference.keyLabel"
						label="Field Label*"
						:disabled="!extendedPreference.keyId"
						:rules="{required: true, max:255}"
					/>
					<TextField
						v-if="extendedPreference.fieldTypeId == 1 && extendedPreference.hasDefaultValue"
						v-model="extendedPreference.defaultFreeTextValue"
						label="Default Value*"
						:disabled="!extendedPreference.keyId"
						rules="required"
					/>
					<Dropdown
						v-if="extendedPreference.fieldTypeId == 2 && extendedPreference.hasDefaultValue"
						v-model="extendedPreference.defaultSingleSelectValueId"
						label="Default Value*"
						:items="extendedPreferenceDropdownValues"
						rules="required"
					/>
					<div class="cassie-horizontal-md d-flex flex-row">
						<v-switch
							v-model="extendedPreference.hideFromUi"
							:disabled="!extendedPreference.keyId"
							label="Hide from UI"
							class="cassie-input-width-sm"
						/>
						<v-switch
							v-model="extendedPreference.isMandatory"
							:disabled="!extendedPreference.keyId"
							label="Is Mandatory"
							class="cassie-input-width-sm"
						/>
						<v-switch
							v-model="extendedPreference.isEditable"
							:disabled="!extendedPreference.keyId"
							label="Editable"
							class="cassie-input-width-sm"
							@change="changeIsEditable"
						/>
						<v-switch
							v-model="extendedPreference.hasDefaultValue"
							label="Use Default Value"
							class="cassie-input-width-sm"
							:disabled="!extendedPreference.fieldTypeId == 1 || !extendedPreference.fieldTypeId == 2 || !extendedPreference.isEditable"
						/>
					</div>
					<PrimaryActionButton @click="!editExtendedPreference ? addExtendedPref() : updateExtendedPref()">
						{{ !editExtendedPreference ? 'Add' : 'Update' }}
					</PrimaryActionButton>
				</div>
			</div>
			<v-simple-table>
				<template #default>
					<thead>
						<tr>
							<th
								scope="col"
								class="text-left"
							>
								Extended Preference
							</th>
							<th
								scope="col"
								class="text-left"
							>
								Field Type
							</th>
							<th
								scope="col"
								class="text-left"
							>
								Field Label
							</th>
							<th
								scope="col"
								class="text-left"
							>
								Hide From UI
							</th>
							<th
								scope="col"
								class="text-left"
							>
								Is Mandatory
							</th>
							<th
								scope="col"
								class="text-left"
							>
								Editable
							</th>
							<th
								scope="col"
								class="text-left"
							>
								Default Value
							</th>
							<th
								scope="col"
								class="text-right"
							>
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(extendedPref, index) in channelToEdit.extendedPrefs"
							:key="extendedPref.keyId"
						>
							<td>
								{{ extendedPref.keyName }}
							</td>
							<td>
								{{ extendedPref.fieldTypeName }}
							</td>
							<td>
								{{ extendedPref.keyLabel }}
							</td>
							<td>
								<v-icon>
									{{ extendedPref.hideFromUi ? 'mdi-check' : 'mdi-close' }}
								</v-icon>
							</td>
							<td>
								<v-icon>
									{{ extendedPref.isMandatory ? 'mdi-check' : 'mdi-close' }}
								</v-icon>
							</td>
							<td>
								<v-icon>
									{{ extendedPref.isEditable ? 'mdi-check' : 'mdi-close' }}
								</v-icon>
							</td>
							<td>
								{{ mappedDefaultValue(extendedPref.keyId, extendedPref.fieldTypeId, extendedPref.defaultFreeTextValue, extendedPref.defaultSingleSelectValueId) }}
							</td>
							<td class="text-right">
								<IconButton
									small
									@click="editExtendedPref(extendedPref, index)"
								>
									mdi-pencil
								</IconButton>
								<IconButton
									small
									@click="removeExtendedPref(index)"
								>
									mdi-trash-can
								</IconButton>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('close')">
				Cancel
			</SecondaryActionButton>
			<v-spacer />
			<PrimaryActionButton
				:disabled="showExtendedPreferenceCard"
				@click="submit"
			>
				Save
			</PrimaryActionButton>
		</template>
	</Modal>
</template>
<script>
import Modal from '../../../../../../shared/components/modal.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import TextButton from '../../../../../../shared/components/text-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import { getExtendedPreferences } from '../../../../../../shared/utils/api/extended-preferences.js'
import IconButton from '../../../../../../shared/components/icon-button.vue'

const clone = data => JSON.parse(JSON.stringify(data))
const FREE_TEXT = 1
const SINGLE_SELECT = 2

export default {
	components: { IconButton, Modal, PrimaryActionButton, SecondaryActionButton, Dropdown, TextButton, TextField },
	props: {
		channel: Object,
		availableFields: {
			type: Array,
			default: () => []
		},
		subGroupIndex: Number,
		parentGroupIndex: Number,
		channelIndex: Number
	},
	data () {
		return {
			channelToEdit: clone(this.channel),
			selectedFields: [],
			showExtendedPreferenceCard: false,
			extendedPreferenceKeys: null,
			extendedPreferenceFieldTypes: null,
			editExtendedPreference: false,
			editedExtendedPrefIndex: null,
			extendedPreference: {
				keyId: null,
				keyName: null,
				keyLabel: null,
				hideFromUi: false,
				isEditable: true,
				isMandatory: false,
				hasDefaultValue: false,
				fieldTypeId: null,
				fieldTypeName: null,
				defaultFreeTextValue: null,
				defaultSingleSelectValueId: null
			}
		}
	},
	computed: {
		modalTitle () {
			return `Manage Channel: ${this.channel.channelName}`
		},
		availableStandDakFields () {
			return this.availableFields.map(standDak => ({
				text: standDak.columnName,
				value: standDak.columnName
			})).filter(field => !this.channelToEdit.requiredStandDakFields?.includes(field.value))
		},
		selectedExtendedPrefs () {
			return this.channelToEdit.extendedPrefs.map(extendedPref => {
				return extendedPref.keyId
			})
		},
		availableExtendedPrefs () {
			const extendedPreferences = this.extendedPreferenceKeys.map(({ name, extendedPreferenceId, dropdownValues }) => ({
				text: name,
				value: extendedPreferenceId,
				dropdownValues: dropdownValues
			}))
			return this.editExtendedPreference ? extendedPreferences : extendedPreferences.filter(extendedPref => !this.selectedExtendedPrefs.includes(extendedPref.value))
		},
		extendedPreferenceDropdownValues () {
			const selectedExtendedPreferenceKeyDropDowns = this.extendedPreferenceKeys
				.find(({ extendedPreferenceId }) => extendedPreferenceId === this.extendedPreference.keyId?.value)?.dropdownValues
			return selectedExtendedPreferenceKeyDropDowns.map(({ value, valueId }) => ({
				text: value,
				value: valueId
			}))
		},
		dropdownValues () {
			return this.extendedPreferenceKeys
		},
		fieldTypeItems () {
			if (this.extendedPreference.keyId?.dropdownValues != null && this.extendedPreference.keyId?.dropdownValues.length > 0) {
				return this.extendedPreferenceFieldTypes.map(fieldType => ({
					value: fieldType.fieldTypeId,
					text: fieldType.fieldTypeName
				}))
			}
			return [{
				value: 1,
				text: 'Free text'
			}]
		}
	},
	async created () {
		this.formatRequiredStandDakFields()
		const { extendedPreferences, lookupData } = await getExtendedPreferences()
		this.extendedPreferenceKeys = extendedPreferences
		this.extendedPreferenceFieldTypes = lookupData.extendedPreferenceFieldTypes
		this.selectedStandDakFields = this.channel.requiredStandDakFields?.split(',') ?? []
	},
	methods: {
		formatRequiredStandDakFields () {
			if (this.channelToEdit.requiredStandDakFields) {
				this.channelToEdit.requiredStandDakFields = this.channelToEdit.requiredStandDakFields.split(',')
			} else {
				this.channelToEdit.requiredStandDakFields = []
			}
		},
		updateRequiredFields (requiredStandDakFields) {
			this.channelToEdit.requiredStandDakFields = requiredStandDakFields
		},
		updateExtendedPrefs (extendedPrefs) {
			this.channelToEdit.extendedPrefs = extendedPrefs
		},
		addFields () {
			this.updateRequiredFields([
				...this.channelToEdit.requiredStandDakFields,
				...this.selectedFields
			])
			this.selectedFields = []
		},
		addExtendedPref () {
			const newExtendedPref = clone(this.extendedPreference)
			newExtendedPref.keyName = this.dropdownValues.find(({ extendedPreferenceId }) => extendedPreferenceId === newExtendedPref.keyId.value)?.name
			newExtendedPref.keyId = this.extendedPreference.keyId.value
			newExtendedPref.fieldTypeName = this.fieldTypeItems.find(({ value }) => value === newExtendedPref.fieldTypeId)?.text
			this.updateExtendedPrefs([
				...this.channelToEdit.extendedPrefs,
				newExtendedPref
			])
			this.extendedPreference = {
				keyId: null,
				keyName: null,
				keyLabel: null,
				hideFromUi: false,
				isEditable: false,
				isMandatory: false,
				hasDefaultValue: false,
				fieldTypeId: null,
				fieldTypeName: null,
				defaultFreeTextValue: null,
				defaultSingleSelectValueId: null
			}
			this.showExtendedPreferenceCard = false
		},
		removeStandDakField (index) {
			this.$delete(this.channelToEdit.requiredStandDakFields, index)
		},
		removeExtendedPref (index) {
			this.$delete(this.channelToEdit.extendedPrefs, index)
		},
		submit () {
			this.channelToEdit.requiredStandDakFields = this.channelToEdit.requiredStandDakFields.join(',')
			this.$emit('edit', this.parentGroupIndex, this.subGroupIndex, this.channelToEdit, this.channelIndex)
		},
		mappedDefaultValue (keyId, fieldTypeId, defaultFreeTextValue, defaultSingleSelectValueId) {
			if (fieldTypeId === FREE_TEXT) {
				return defaultFreeTextValue ?? ''
			} else if (fieldTypeId === SINGLE_SELECT) {
				if (this.extendedPreferenceKeys == null) return ''
				const key = this.extendedPreferenceKeys?.find(x => x.extendedPreferenceId === keyId)
				const dropDownItem = key.dropdownValues?.find(x => x.valueId === defaultSingleSelectValueId)
				return dropDownItem?.value ?? ''
			}
		},
		editExtendedPref (extendedPreference, index) {
			this.editExtendedPreference = true
			this.extendedPreference = clone(extendedPreference)
			this.extendedPreference.keyId = {
				value: extendedPreference.keyId,
				text: extendedPreference.keyName,
				dropdownValues: this.extendedPreferenceKeys.find(({ extendedPreferenceId }) => extendedPreferenceId === extendedPreference.keyId)?.dropdownValues
			}
			this.editedExtendedPrefIndex = index
			this.showExtendedPreferenceCard = true
		},
		updateExtendedPref () {
			const updatedExtendedPref = clone(this.extendedPreference)
			updatedExtendedPref.keyName = this.dropdownValues.find(({ extendedPreferenceId }) => extendedPreferenceId === updatedExtendedPref.keyId.value)?.name
			updatedExtendedPref.keyId = this.extendedPreference.keyId.value
			updatedExtendedPref.fieldTypeName = this.fieldTypeItems.find(({ value }) => value === updatedExtendedPref.fieldTypeId)?.text
			const channelExtendedPrefs = clone(this.channelToEdit.extendedPrefs)
			this.$set(channelExtendedPrefs, this.editedExtendedPrefIndex, updatedExtendedPref)
			this.updateExtendedPrefs([
				...channelExtendedPrefs
			])
			this.extendedPreference = {
				keyId: null,
				keyName: null,
				keyLabel: null,
				hideFromUi: false,
				isEditable: false,
				isMandatory: false,
				hasDefaultValue: false,
				fieldTypeId: null,
				fieldTypeName: null,
				defaultFreeTextValue: null,
				defaultSingleSelectValueId: null
			}
			this.showExtendedPreferenceCard = false
			this.editedExtendedPrefIndex = null
			this.editExtendedPreference = false
		},
		changeIsEditable (value) {
			if (!value) this.extendedPreference.hasDefaultValue = true
		}
	}
}
</script>
