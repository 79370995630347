<template>
	<validation-observer #default="{ handleSubmit, valid }">
		<Modal
			width="1000px"
		>
			<template #modal-title>
				Manage Response Channels
			</template>
			<template #modal-title-action>
				<TextButton
					:disabled="!valid"
					@click="addResponseChannel"
				>
					<v-icon>
						mdi-plus
					</v-icon>
					Add Response Channel
				</TextButton>
			</template>
			<template #modal-content>
				<v-simple-table dense>
					<template #default>
						<thead>
							<tr>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									Response Channel Name
								</th>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									Response Channel Code
								</th>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									{{ 'Brand' | useLabels }}
								</th>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									Default?
								</th>
								<th
									class="text-right"
									style="width: 10%;"
									scope="col"
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(responseChannel, index) in newResponseChannels"
								:key="index"
							>
								<td>
									<TextField
										v-model="responseChannel.responseChannelName"
										small
										label="Response channel name *"
										class="py-2"
										:rules="{required: true, max:100}"
									/>
								</td>
								<td>
									<TextField
										v-model="responseChannel.responseChannelCode"
										small
										label="Reponse channel code *"
										class="py-2"
										:rules="{required: true, max:10}"
									/>
								</td>
								<td>
									<Dropdown
										v-model="responseChannel.brandId"
										:items="brandOptions"
										custom-sort
										small
										class="py-2"
										rules="required"
										:label="'Brand *' | useLabels"
									/>
								</td>
								<td>
									<v-switch
										:input-value="responseChannel.isDefault"
										label="Is Default"
										class="mt-0"
										hide-details
										:disabled="isDefaultDisabled(index) || responseChannel.brandId == null"
										@change="updateIsDefault(index, $event)"
									/>
								</td>
								<td class="text-right">
									<IconButton
										small
										small-icon
										@click="removeResponseChannel(index)"
									>
										mdi-trash-can
									</IconButton>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton @click="handleSubmit(submit)">
					Confirm
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import TextButton from '../../../../../../shared/components/text-button.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { brandOptions } from '../../../../../../shared/state/brands.js'
export default {
	components: { IconButton, Dropdown, TextField, PrimaryActionButton, SecondaryActionButton, Modal, TextButton },
	props: {
		responseChannels: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			brandOptions
		}
	},
	data () {
		return {
			newResponseChannels: this.responseChannels
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		removeResponseChannel (index) {
			this.$delete(this.newResponseChannels, index)
		},
		addResponseChannel () {
			this.newResponseChannels = [...this.newResponseChannels, {
				responseChannelName: null,
				responseChannelCode: null,
				brandId: null,
				isDefault: false
			}]
		},
		submit () {
			this.$emit('update:responseChannels', this.newResponseChannels)
			this.close()
		},
		isDefaultDisabled (index) {
			if (!this.newResponseChannels[index].isDefault === true) {
				if (this.newResponseChannels.find(channel =>
					channel.responseChannelId !== this.newResponseChannels[index].responseChannelId &&
					channel.brandId === this.newResponseChannels[index].brandId && channel.isDefault === true)) {
					return true
				}
			}
			return false
		},
		updateIsDefault (index, value) {
			this.newResponseChannels[index].isDefault = value
		}
	}
}
</script>
