<template>
	<v-expansion-panels
		:value="preferenceGroupsPanelsValue"
		multiple
		class="cassie-data-subject-expansion-panels"
	>
		<v-expansion-panel
			v-for="({ parentGroupId, parentGroupName, subGroups }, parentGroupIndex) in filteredPreferenceGroups(preferenceGroups)"
			:key="parentGroupIndex"
			@change="changePreferenceGroupsPanelsValue(parentGroupId)"
		>
			<v-expansion-panel-header
				color="nav-bar-background white--text"
			>
				{{ parentGroupName }}
				<template #actions>
					<slot
						name="parent-group-header"
						:parent-group-index="parentGroupIndex"
						:parent-group-name="parentGroupName"
					/>
					<v-icon class="white--text">
						mdi-chevron-up
					</v-icon>
				</template>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				<!-- Sub Groups Panels -->
				<v-expansion-panels
					:value="subGroupsPanelsValue(parentGroupId, subGroups)"
					multiple
					@change="value => setSubGroupsPanelsValue(parentGroupId, value)"
				>
					<v-expansion-panel
						v-for="({ subGroupName, channels, subGroupId }, subGroupIndex) in filteredSubGroups(subGroups)"
						:key="subGroupId"
					>
						<v-expansion-panel-header
							color="blue darken-2 white--text"
							class="relative"
						>
							<div class="subgroup-name">
								{{ subGroupName }}
							</div>
							<div
								v-if="!configMode"
								class="radio-buttons-wrapper fixed-radio-buttons"
								:class="{ 'radio-buttons-create': isCreating, 'radio-buttons-edit': !isCreating }"
							>
								<v-radio-group
									v-if="!viewMode"
									:value="getSubGroupSelection(parentGroupId, subGroupId)"
									row
									dark
									dense
									class="d-flex align-center custom-radio-group"
									@click.stop
								>
									<v-radio
										:label="'Opted-In'"
										:value="1"
										class="radio-option"
										@click="onRadioClick(1, parentGroupId, subGroupId)"
										@click.stop
									/>
									<v-radio
										:label="'Opted-Out'"
										:value="0"
										class="radio-option"
										@click="onRadioClick(0, parentGroupId, subGroupId)"
										@click.stop
									/>
									<v-radio
										:label="isCreating ? 'No Pref' : 'No Change'"
										:value="NO_CHANGE_VALUE"
										class="radio-option"
										@click="onRadioClick(NO_CHANGE_VALUE, parentGroupId, subGroupId)"
										@click.stop
									/>
								</v-radio-group>
							</div>

							<template #actions>
								<slot
									name="sub-group-header"
									:sub-group-name="subGroupName"
									:parent-group-index="parentGroupIndex"
									:sub-group-index="subGroupIndex"
									:parent-group-name="parentGroupName"
									:channels="channels"
								/>
								<v-icon class="white--text">
									mdi-chevron-up
								</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<slot
								name="table"
								:channels="channels"
								:parent-group-name="parentGroupName"
							>
								<v-simple-table
									class="my-n4"
									dense
								>
									<template #default>
										<thead>
											<tr>
												<slot name="header" />
											</tr>
										</thead>
										<tbody>
											<slot
												name="rows"
												:channels="channels"
												:parent-group-index="parentGroupIndex"
												:sub-group-index="subGroupIndex"
												:parent-group-name="parentGroupName"
											/>
											<tr v-if="!channels.length">
												<td
													colspan="7"
													class="grey--text text-center"
												>
													No eligible preferences
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</slot>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import {
	selectedBrand,
	showCsPortalBrandSelector
} from './../../../shared/state/brands.js'
export default {
	props: {
		preferenceGroups: {
			type: Array,
			default: () => []
		},
		viewMode: {
			type: Boolean,
			default: false
		},
		configMode: {
			type: Boolean,
			default: false
		},
		brandId: Number,
		isCreating: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			selectedBrand,
			showCsPortalBrandSelector
		}
	},
	data () {
		return {
			preferenceGroupsPanels: {},
			subGroupsPanels: {},
			parentGroupSelections: {},
			NO_CHANGE_VALUE: -1
		}
	},
	computed: {
		preferenceGroupsPanelsValue () {
			return this.preferenceGroups?.map(({ parentGroupId }, index) => this.preferenceGroupsPanels[parentGroupId] ? undefined : index).filter(i => i !== undefined)
		}
	},
	methods: {
		filteredPreferenceGroups (preferenceGroups) {
			if (this.configMode) {
				return preferenceGroups?.map(prefGroup => ({
					...prefGroup,
					subGroup: prefGroup.subGroups.map(subGroup => ({
						...subGroup,
						channels: subGroup.channels
					}))
				}))
			} else if (!this.viewMode) {
				return preferenceGroups?.map(prefGroup => ({
					...prefGroup,
					subGroup: prefGroup.subGroups.map(subGroup => ({
						...subGroup,
						channels: subGroup.channels.filter(({ brandId, canBeAdded }) =>
							(
								(brandId === this.selectedBrand || brandId === 0) ||
								this.selectedBrand === 0
							) && canBeAdded
						)
					})).filter(({ channels }) => channels.length > 0)
				})).filter(({ subGroup }) => subGroup.length > 0)
			} else {
				return preferenceGroups.map(prefGroup => ({
					...prefGroup,
					subGroup: prefGroup.subGroups.map(subGroup => ({
						...subGroup,
						channels: this.brandId !== 0
							? subGroup.channels.filter(({ brandId }) =>
								(
									(brandId === this.selectedBrand || brandId === 0 || brandId === this.brandId)
								)
							)
							: subGroup.channels
					}))
				})).filter(({ subGroup }) => subGroup.some(({ channels }) => channels.length || this.configMode))
			}
		},
		filteredSubGroups (subGroups) {
			if (this.configMode) {
				return subGroups.map(subGroup => ({
					...subGroup,
					channels: subGroup.channels
				}))
			} else if (!this.viewMode) {
				return subGroups.map(subGroup => ({
					...subGroup,
					channels: subGroup.channels.filter(({ brandId, canBeAdded }) =>
						(
							(brandId === this.selectedBrand || brandId === 0) ||
							this.selectedBrand === 0
						) && canBeAdded
					)
				})).filter(({ channels }) => channels.length > 0)
			} else {
				return subGroups.map(subGroup => ({
					...subGroup,
					channels: this.brandId !== 0
						? subGroup.channels.filter(({ brandId }) =>
							(
								(brandId === this.selectedBrand || brandId === 0 || brandId === this.brandId)
							)
						)
						: subGroup.channels
				})).filter(({ channels }) => channels.length)
			}
		},
		changePreferenceGroupsPanelsValue (parentGroupId) {
			this.preferenceGroupsPanels = {
				...this.preferenceGroupsPanels,
				[parentGroupId]: !this.preferenceGroupsPanels[parentGroupId]
			}
		},
		subGroupsPanelsValue (parentGroupId, subGroups) {
			if (!Object.keys(this.subGroupsPanels).includes(String(parentGroupId))) {
				return subGroups.map((_, index) => index)
			} else {
				return this.subGroupsPanels[parentGroupId]
			}
		},
		setSubGroupsPanelsValue (parentGroupId, value) {
			this.subGroupsPanels = {
				...this.subGroupsPanels,
				[parentGroupId]: value
			}
		},
		getSubGroupSelection (parentGroupId, subGroupId) {
			if (!this.parentGroupSelections[parentGroupId]) {
				this.$set(this.parentGroupSelections, parentGroupId, {})
			}
			const value = this.parentGroupSelections[parentGroupId][subGroupId]
			return value !== undefined ? value : null
		},
		resetGroupSelection (parentGroupId, subGroupId) {
			if (this.parentGroupSelections[parentGroupId]) {
				this.$set(this.parentGroupSelections[parentGroupId], subGroupId, null)
			}
		},
		onRadioClick (value, parentGroupId, subGroupId) {
			if (!this.parentGroupSelections[parentGroupId]) {
				this.$set(this.parentGroupSelections, parentGroupId, {})
			}

			const currentValue = this.parentGroupSelections[parentGroupId][subGroupId]

			if ((value === 1 || value === 0) && currentValue === value) {
				this.$set(this.parentGroupSelections[parentGroupId], subGroupId, null)
			} else {
				this.$set(this.parentGroupSelections[parentGroupId], subGroupId, value)
			}
			this.$emit('cascade-selection', parentGroupId, subGroupId, value)
		}
	}
}
</script>

<style lang="scss">
.cassie-data-subject-expansion-panels {
	.v-expansion-panel-header {
		min-height: 32px !important;
		padding: 12px !important;
		position: relative;
	}
	.v-expansion-panel-content {
		padding: 0 !important;
	}
	.v-expansion-panel-content__wrap {
		padding: 16px !important;
	}
	.subgroup-name {
		width: 17%;
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.radio-buttons-wrapper.fixed-radio-buttons {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: left 0.3s ease;
		&.radio-buttons-create {
			left: calc(17% + 26px);
		}
		&.radio-buttons-edit {
			left: calc(27% + 22px);
		}
	}

	.expansion-panel-header {
		.v-label {
			color: #fff;
		}
	}
}
</style>
