import { configApi } from '../http-client.js'

export const getIpWhitelists = () => {
	return configApi.get('/CSPortal/api/Config/IPWhitelist').then(({ data: { ipWhitelist } }) => {
		return {
			data: ipWhitelist.map(whitelist => ({
				id: whitelist.id,
				ipAddress: whitelist.ipAddress,
				description: whitelist.description
			}))
		}
	})
}

export const postIpWhitelist = ipWhitelist => {
	return configApi.post('/CSPortal/api/Config/IPWhitelist', ipWhitelist)
}

export const deleteIpWhitelist = ipWhitelist => {
	return configApi.delete(`/CSPortal/api/Config/IPWhitelist/${ipWhitelist}`)
}
