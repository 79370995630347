<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal width="600px">
			<template #modal-title>
				{{ isEdit ? 'Edit Preference Group' : 'Create New Preference Group' }}
			</template>
			<template #modal-content>
				<TextField
					v-model="newParentGroupName"
					label="Group Name *"
					:rules="{required: true, max:100}"
				/>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<v-spacer />
				<PrimaryActionButton @click="handleSubmit(submit)">
					{{ isEdit ? 'Save' : 'Create' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>
<script>
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Modal from '../../../../../../shared/components/modal.vue'
export default {
	components: { Modal, TextField, SecondaryActionButton, PrimaryActionButton },
	props: {
		parentGroupName: String,
		parentGroupIndex: Number
	},
	data () {
		return {
			newParentGroupName: this.parentGroupName
		}
	},
	computed: {
		isEdit () {
			return !!this.parentGroupName
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		submit () {
			this.$emit(this.isEdit ? 'edit' : 'add', this.newParentGroupName, this.parentGroupIndex)
			this.close()
		}
	}
}
</script>
