<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Brands
			</template>
			<template #body>
				<v-container fluid>
					<v-row
						class="align-center"
					>
						<v-col
							cols="4"
							class="py-0"
						>
							<TextField
								:value="brandFieldLabel"
								:label="'Brand Label *' | useLabels"
								style="width: 80%;"
								:disabled="!userFullPermissions"
								@input="$emit('update:brandFieldLabel', $event)"
							/>
						</v-col>
						<v-col
							cols="6"
							class="py-0"
						>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="onlyShowDataSubjectsAssociatedWithSelectedBrand"
								label="Only Show Data Subjects Associated With CS Portal User's Selected Brand"
								@change="$emit('update:onlyShowDataSubjectsAssociatedWithSelectedBrand', $event)"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Portal Configuration
			</template>
			<template #body>
				<v-container fluid>
					<v-row
						class="align-center"
					>
						<v-col
							cols="4"
						>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="canCreateDataSubjects"
								label="Can Create Data Subject"
								@change="$emit('update:canCreateDataSubjects', $event)"
							/>
						</v-col>
						<v-col
							cols="4"
						>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="canCreateCsPortalUsers"
								label="Can Create CS Portal Users"
								@change="$emit('update:canCreateCsPortalUsers', $event)"
							/>
						</v-col>
					</v-row>
					<v-row
						class="align-center"
					>
						<v-col
							cols="4"
						>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="masterCanEditPreferences"
								label="Can Edit Preferences"
								@change="$emit('update:masterCanEditPreferences', $event)"
							/>
						</v-col>
						<v-col
							cols="4"
						>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="masterCanEditContactDetails"
								label="Can Edit Contact Details"
								@change="$emit('update:masterCanEditContactDetails', $event)"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</SectionCard>
		<SectionCard v-if="canCreateDataSubjects || masterCanEditPreferences">
			<template #title>
				Edit Preference Options
			</template>
			<template #body>
				<div class="cassie-vertical-md">
					<v-simple-table style="width: 80%;">
						<template #default>
							<thead>
								<tr>
									<th
										class="text-left"
										style="width: 15%;"
										scope="col"
									>
										Field
									</th>
									<th
										class="text-left"
										style="width: 10%;"
										scope="col"
									>
										Visible
									</th>
									<th
										class="text-left"
										style="width: 15%;"
										scope="col"
									>
										Is Mandatory
									</th>
									<th
										class="text-left"
										style="width: 20%;"
										scope="col"
									>
										Label
									</th>
									<th
										class="text-left"
										style="width: 15%;"
										scope="col"
									>
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-left">
										Response Channel
									</td>
									<td>
										<v-switch
											:disabled="!userFullPermissions"
											:input-value="showResponseChannelForPrefSubmissions"
											class="mt-0"
											hide-details
											@change="$emit('update:showResponseChannelForPrefSubmissions', $event)"
										/>
									</td>
									<td>
										<v-switch
											:disabled="!userFullPermissions"
											:input-value="responseChannelForSubmissionsMandatory"
											class="mt-0"
											hide-details
											@change="$emit('update:responseChannelForSubmissionsMandatory', $event)"
										/>
									</td>
									<td>
										<TextField
											:disabled="!userFullPermissions"
											:value="responseChannelFieldLabel"
											style="width: 80%;"
											:rules="{max:60}"
											@input="$emit('update:responseChannelFieldLabel', $event)"
										/>
									</td>
									<td v-if="userFullPermissions">
										<IconButton
											tooltip-text="Manage Response Channels"
											@click="showResponseChannelsModal = true"
										>
											mdi-pencil
										</IconButton>
									</td>
								</tr>
								<tr>
									<td class="text-left">
										Source Code
									</td>
									<td>
										<v-switch
											:disabled="!userFullPermissions"
											:input-value="showSourceCodeForPrefSubmissions"
											class="mt-0"
											hide-details
											@change="$emit('update:showSourceCodeForPrefSubmissions', $event)"
										/>
									</td>
									<td>
										<v-switch
											:disabled="!userFullPermissions"
											:input-value="sourceCodeForSubmissionsMandatory"
											class="mt-0"
											hide-details
											@change="$emit('update:sourceCodeForSubmissionsMandatory', $event)"
										/>
									</td>
									<td class="text-left">
										<TextField
											:disabled="!userFullPermissions"
											:value="sourceCodeFieldLabel"
											style="width: 80%;"
											:rules="{max:60}"
											@input="$emit('update:sourceCodeFieldLabel', $event)"
										/>
									</td>
									<td v-if="userFullPermissions">
										<IconButton
											tooltip-text="Manage Source Codes"
											@click="showSourceCodesModal = true"
										>
											mdi-pencil
										</IconButton>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</div>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Channel Options
			</template>
			<template #body>
				<v-container fluid>
					<v-row>
						<v-col cols="4">
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="canEditValidFromAndToFieldsForPrefSubmissions"
								label="Can Edit Valid From and Valid To Fields"
								@change="$emit('update:canEditValidFromAndToFieldsForPrefSubmissions', $event)"
							/>
						</v-col>
						<v-col cols="4">
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="privacyPolicyMandatory"
								label="Privacy Policy Mandatory"
								@change="$emit('update:privacyPolicyMandatory', $event)"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Additional Options
			</template>
			<template #body>
				<v-container fluid>
					<v-row>
						<v-col
							cols="4"
						>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="showValidFromAndToFieldsForPrefSubmissions"
								label="Display Valid From and Valid To Fields"
								@change="$emit('update:showValidFromAndToFieldsForPrefSubmissions', $event)"
							/>
						</v-col>
						<v-col
							cols="4"
						>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="dataSubjectsWithNoPreferencesTickedByDefault"
								label="'Display Data Subjects With No Preferences' Checkbox Selected by Default"
								@change="$emit('update:dataSubjectsWithNoPreferencesTickedByDefault', $event)"
							/>
						</v-col>
					</v-row>
					<v-row class="align-center">
						<v-col
							cols="4"
						>
							<v-switch
								:disabled="!userFullPermissions"
								:input-value="auditHistorySortNewestToOldest"
								label="Sort Submission History Newest to Oldest"
								@change="$emit('update:auditHistorySortNewestToOldest', $event)"
							/>
						</v-col>
						<v-col
							cols="4"
						>
							<TextField
								:disabled="!userFullPermissions"
								:value="auditHistoryExpandedByDefault"
								type="number"
								class="cassie-input-width-md"
								label="Submission History Expanded Items *"
								tooltip-text="Specify how many submission history items are expanded by default"
								:rules="{required:true, numeric: true}"
								@input="$emit('update:auditHistoryExpandedByDefault', $event)"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col
							cols="4"
						>
							<TextField
								:disabled="!userFullPermissions"
								:value="customLogoutUrl"
								:rules="{max: 255}"
								label="Custom logout url"
								@input="$emit('update:customLogoutUrl', $event)"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</SectionCard>
		<CsPortalConfigResponseChannelsModal
			v-if="showResponseChannelsModal"
			:response-channels="responseChannels"
			@close="showResponseChannelsModal = false"
			@update:responseChannels="$emit('update:responseChannels', $event)"
		/>
		<CsPortalConfigSourceCodesModal
			v-if="showSourceCodesModal"
			:source-codes="sourceCodes"
			@close="showSourceCodesModal = false"
			@update:sourceCodes="$emit('update:sourceCodes', $event)"
		/>
	</div>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import CsPortalConfigResponseChannelsModal from './cs-portal-config-response-channels-modal.vue'
import CsPortalConfigSourceCodesModal from './cs-portal-config-source-codes-modal.vue'
import { postIpWhitelist, getIpWhitelists, deleteIpWhitelist } from '../../../../../../shared/utils/api/cs-portal-config-whitelisting.js'
import {
	canEditValidFromAndToFieldsForPrefSubmissions,
	showValidFromAndToFieldsForPrefSubmissions,
	canCreateDataSubjects,
	masterCanEditPreferences,
	masterCanEditContactDetails,
	auditHistorySortNewestToOldest,
	auditHistoryExpandedByDefault,
	privacyPolicyMandatory,
	canCreateCsPortalUsers,
	showResponseChannelForPrefSubmissions,
	brandFieldLabel,
	customLogoutUrl,
	dataSubjectsWithNoPreferencesTickedByDefault
} from '../../../../../../shared/state/configuration.js'
export default {
	components: {
		CsPortalConfigResponseChannelsModal,
		CsPortalConfigSourceCodesModal,
		TextField,
		IconButton,
		SectionCard
	},
	props: {
		headerFooterCombinations: {
			type: Array,
			default: () => []
		},
		responseChannels: {
			type: Array,
			default: () => []
		},
		sourceCodes: {
			type: Array,
			default: () => []
		},
		onlyShowDataSubjectsAssociatedWithSelectedBrand: {
			type: Boolean,
			default: false
		},
		showResponseChannelForPrefSubmissions,
		responseChannelForSubmissionsMandatory: {
			type: Boolean,
			default: false
		},
		responseChannelFieldLabel: {
			type: String,
			default: ''
		},
		showSourceCodeForPrefSubmissions: {
			type: Boolean,
			default: false
		},
		sourceCodeForSubmissionsMandatory: {
			type: Boolean,
			default: false
		},
		sourceCodeFieldLabel: {
			type: String,
			default: ''
		},
		userFullPermissions: {
			type: Boolean,
			default: false
		},
		canCreateCsPortalUsers,
		canEditValidFromAndToFieldsForPrefSubmissions,
		showValidFromAndToFieldsForPrefSubmissions,
		canCreateDataSubjects,
		masterCanEditPreferences,
		masterCanEditContactDetails,
		auditHistorySortNewestToOldest,
		auditHistoryExpandedByDefault,
		privacyPolicyMandatory,
		brandFieldLabel,
		customLogoutUrl,
		dataSubjectsWithNoPreferencesTickedByDefault
	},
	data () {
		return {
			showResponseChannelsModal: false,
			showSourceCodesModal: false
		}
	},
	methods: {
		postIpWhitelist, getIpWhitelists, deleteIpWhitelist
	}
}
</script>
