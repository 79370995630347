<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Search Criteria Configuration
			</template>
			<template #body>
				<div
					v-if="userFullPermissions"
					class="d-flex cassie-horizontal-md"
				>
					<Dropdown
						v-model="selectedSearchCriteriaFields"
						label="Search Criteria Fields"
						:items="availableSearchCriteriaFields"
						multiple
					/>
					<PrimaryActionButton
						:disabled="!selectedSearchCriteriaFields.length"
						@click="addSearchCriteriaFields"
					>
						Add
					</PrimaryActionButton>
				</div>
				<v-simple-table>
					<template #default>
						<thead>
							<tr>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									Database Field Name
								</th>
								<th
									class="text-left"
									style="width: 40%;"
									scope="col"
								>
									Field Label On Search Screen And Profile Grid
								</th>
								<th
									class="text-center"
									style="width: 15%;"
									scope="col"
								>
									Action
								</th>
								<th
									class="text-left"
									style="width: 15%;"
									scope="col"
								>
									Display Order
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(searchField, index) in searchableFields"
								:key="searchField.fieldName"
							>
								<td class="text-left">
									{{ searchField.fieldName }}
								</td>
								<td class="text-left">
									<TextField
										:disabled="!userFullPermissions"
										:value="searchField.fieldLabel"
										:rules="{ max: 200 }"
										small
										label="Field label"
										@input="updateSearchFieldLabel(searchField.searchFieldId, 'fieldLabel', $event)"
									/>
								</td>
								<td class="text-center">
									<IconButton
										v-if="userFullPermissions"
										@click="deleteSearchField(searchField.searchFieldId)"
									>
										mdi-trash-can
									</IconButton>
								</td>
								<td class="text-left">
									{{ searchField.displayOrder > 0 ? searchField.displayOrder : null }}
									<IconButton
										v-if="searchField.displayOrder && searchField.displayOrder !== 1 && userFullPermissions"
										small
										@click="changeDisplayOrder(index, 'moveUp')"
									>
										mdi-arrow-up
									</IconButton>
									<IconButton
										v-if="searchField.displayOrder && searchField.displayOrder !== searchableFields.length && userFullPermissions"
										small
										@click="changeDisplayOrder(index, 'moveDown')"
									>
										mdi-arrow-down
									</IconButton>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Search Result Configuration
			</template>
			<template #body>
				<div
					v-if="userFullPermissions"
					class="d-flex cassie-horizontal-md"
				>
					<Dropdown
						v-model="selectedSearchResultFields"
						label="Search Result Fields"
						:items="availableSearchResultFields"
						multiple
					/>
					<PrimaryActionButton
						:disabled="!selectedSearchResultFields.length"
						@click="addSearchResultFields"
					>
						Add
					</PrimaryActionButton>
				</div>
				<v-simple-table>
					<template #default>
						<thead>
							<tr>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									Database Field Name
								</th>
								<th
									class="text-left"
									style="width: 40%;"
									scope="col"
								>
									Field Label On Search Screen And Profile Grid
								</th>
								<th
									class="text-center"
									style="width: 15%;"
									scope="col"
								>
									Action
								</th>
								<th
									class="text-left"
									style="width: 15%;"
									scope="col"
								>
									Display Order
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(searchField, index) in searchResultsFields"
								:key="searchField.fieldName"
							>
								<td class="text-left">
									{{ searchField.fieldName }}
								</td>
								<td class="text-left">
									<TextField
										:disabled="!userFullPermissions"
										:value="searchField.fieldLabel"
										:rules="{ max: 200 }"
										small
										label="Field label"
										@input="updateSearchFieldLabel(searchField.searchFieldId, 'fieldLabel', $event)"
									/>
								</td>
								<td class="text-center">
									<IconButton
										v-if="userFullPermissions"
										@click="deleteSearchResultField(searchField.searchFieldId)"
									>
										mdi-trash-can
									</IconButton>
								</td>
								<td class="text-left">
									{{ searchField.searchResultsDisplayOrder > 0 ? searchField.searchResultsDisplayOrder : null }}
									<IconButton
										v-if="searchField.searchResultsDisplayOrder && searchField.searchResultsDisplayOrder !== 1 && userFullPermissions"
										@click="changeSearchResultsDisplayOrder(index, 'moveUp')"
									>
										mdi-arrow-up
									</IconButton>
									<IconButton
										v-if="searchField.searchResultsDisplayOrder && searchField.searchResultsDisplayOrder !== searchResultsFields.length && userFullPermissions"
										@click="changeSearchResultsDisplayOrder(index, 'moveDown')"
									>
										mdi-arrow-down
									</IconButton>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Profile Grid Configuration
			</template>
			<template #body>
				<div
					v-if="userFullPermissions"
					class="d-flex cassie-horizontal-md"
				>
					<Dropdown
						v-model="selectedProfileGridFields"
						label="Profile Grid Fields"
						:items="availableProfileGridFields"
						multiple
					/>
					<PrimaryActionButton
						:disabled="!selectedProfileGridFields.length"
						@click="addProfileGridFields"
					>
						Add
					</PrimaryActionButton>
				</div>
				<v-simple-table>
					<template #default>
						<thead>
							<tr>
								<th
									class="text-left"
									style="width: 30%;"
									scope="col"
								>
									Database Field Name
								</th>
								<th
									class="text-left"
									style="width: 40%;"
									scope="col"
								>
									Field Label On Search Screen And Profile Grid
								</th>
								<th
									class="text-center"
									style="width: 15%;"
									scope="col"
								>
									Action
								</th>
								<th
									class="text-left"
									style="width: 15%;"
									scope="col"
								>
									<!-- Placeholder to ensure column alignment -->
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="profileField in profileGridFields"
								:key="profileField.fieldName"
							>
								<td class="text-left">
									{{ profileField.fieldName }}
								</td>
								<td class="text-left">
									<TextField
										:disabled="!userFullPermissions"
										:value="profileField.fieldLabel"
										:rules="{ max: 200 }"
										small
										label="Field label"
										@input="updateProfileFieldLabel(profileField.searchFieldId, 'fieldLabel', $event)"
									/>
								</td>
								<td class="text-center">
									<IconButton
										v-if="userFullPermissions"
										@click="deleteProfileGridField(profileField.searchFieldId)"
									>
										mdi-trash-can
									</IconButton>
								</td>
								<td class="text-left">
									<!-- Placeholder to ensure column alignment -->
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</template>
		</SectionCard>
	</div>
</template>

<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { searchFieldFilter } from '../../../../../shared/state/configuration.js'

export default {
	components: { TextField, SectionCard, IconButton, Dropdown, PrimaryActionButton },
	props: {
		searchFields: {
			type: Array,
			default: () => []
		},
		availableFields: {
			type: Array,
			default: () => []
		},
		userFullPermissions: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			selectedSearchCriteriaFields: [],
			selectedSearchResultFields: [],
			selectedProfileGridFields: []
		}
	},
	computed: {
		availableFieldItems () {
			return this.searchFields.map(({ fieldName, searchFieldId, isSearchableBy, includeInSearchResults, showInProfilesTable }) => ({
				text: this.availableFields.find(({ columnName }) => columnName.toLowerCase() === fieldName.toLowerCase())?.columnName,
				value: searchFieldId,
				isSearchableBy: isSearchableBy,
				includeInSearchResults: includeInSearchResults,
				showInProfilesTable: showInProfilesTable
			})).sort((a, b) => a.text.localeCompare(b.text))
		},
		maxSearchResultsFields () {
			return 12
		},
		maxProfileGridFields () {
			return 12
		},
		availableSearchCriteriaFields () {
			return this.availableFieldItems.filter(({ isSearchableBy, text }) => isSearchableBy === false && searchFieldFilter(text))
		},
		availableSearchResultFields () {
			return this.availableFieldItems.filter(({ includeInSearchResults }) => includeInSearchResults === false)
		},
		availableProfileGridFields () {
			return this.availableFieldItems.filter(({ showInProfilesTable }) => !showInProfilesTable)
		},
		mappedSearchFields () {
			const searchFields = this.searchFields
			return searchFields.map(searchField => ({
				...searchField,
				displayOrder: searchField.displayOrder === 0 ? null : searchField.displayOrder,
				searchResultsDisplayOrder: searchField.searchResultsDisplayOrder === 0 ? null : searchField.searchResultsDisplayOrder
			})).sort((a, b) => {
				// ensure search fields with no display order is last
				return (a.displayOrder === null) - (b.displayOrder === null) || +(a.displayOrder > b.displayOrder) || -(a.displayOrder < b.displayOrder)
			})
		},
		searchableFields () {
			const criteriaFields = this.searchFields.filter(({ isSearchableBy }) => isSearchableBy)
			return criteriaFields.sort((a, b) => {
				// ensure search fields with no display order is last
				return (a.displayOrder === null) - (b.displayOrder === null) || +(a.displayOrder > b.displayOrder) || -(a.displayOrder < b.displayOrder)
			})
		},
		searchResultsFields () {
			const resultFields = this.searchFields.filter(({ includeInSearchResults }) => includeInSearchResults)
			return resultFields.sort((a, b) => {
				// ensure search fields with no display order is last
				return (a.searchResultsDisplayOrder === null) - (b.searchResultsDisplayOrder === null) || +(a.searchResultsDisplayOrder > b.searchResultsDisplayOrder) ||
					-(a.searchResultsDisplayOrder < b.searchResultsDisplayOrder)
			})
		},
		profileGridFields () {
			const profileFields = this.searchFields.filter(({ showInProfilesTable }) => showInProfilesTable)
			return profileFields.sort((a, b) => (a.displayOrder === null) - (b.displayOrder === null) || +(a.displayOrder > b.displayOrder) || -(a.displayOrder < b.displayOrder))
		}
	},
	methods: {
		updateSearchFieldLabel (fieldId, property, value) {
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			const matchingField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
			matchingField[property] = value
			this.$emit('update:searchFields', searchFields)
		},
		updateProfileFieldLabel (fieldId, property, value) {
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			const matchingField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
			matchingField[property] = value
			this.$emit('update:searchFields', searchFields)
		},
		changeDisplayOrder (index, direction) {
			const fieldId = this.searchableFields[index].searchFieldId
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			const moveUp = 'moveUp'
			const selectedSearchField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
			const previousSearchField = searchFields.find(({ displayOrder }) => displayOrder === selectedSearchField.displayOrder - 1)
			const nextSearchField = searchFields.find(({ displayOrder }) => displayOrder === selectedSearchField.displayOrder + 1)
			if (direction === moveUp) {
				previousSearchField.displayOrder += 1
				selectedSearchField.displayOrder -= 1
			} else {
				selectedSearchField.displayOrder += 1
				nextSearchField.displayOrder -= 1
			}
			this.$emit('update:searchFields', searchFields)
		},
		changeSearchResultsDisplayOrder (index, direction) {
			const fieldId = this.searchResultsFields[index].searchFieldId
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			const moveUp = 'moveUp'
			const selectedResultsField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
			const previousResultsField = searchFields.find(({ searchResultsDisplayOrder }) => searchResultsDisplayOrder === selectedResultsField.searchResultsDisplayOrder - 1)
			const nextResultsField = searchFields.find(({ searchResultsDisplayOrder }) => searchResultsDisplayOrder === selectedResultsField.searchResultsDisplayOrder + 1)
			if (direction === moveUp) {
				previousResultsField.searchResultsDisplayOrder += 1
				selectedResultsField.searchResultsDisplayOrder -= 1
			} else {
				selectedResultsField.searchResultsDisplayOrder += 1
				nextResultsField.searchResultsDisplayOrder -= 1
			}
			this.$emit('update:searchFields', searchFields)
		},
		addSearchCriteriaFields () {
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			const startOrder = this.searchableFields.length + 1
			this.selectedSearchCriteriaFields.forEach((fieldId, index) => {
				const addedSearchField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
				addedSearchField.isSearchableBy = true
				addedSearchField.displayOrder = startOrder + index
			})
			this.selectedSearchCriteriaFields = []
			this.$emit('update:searchFields', searchFields)
		},
		addSearchResultFields () {
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			const startOrder = this.searchResultsFields.length + 1
			this.selectedSearchResultFields.forEach((fieldId, index) => {
				const addedResultField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
				addedResultField.includeInSearchResults = true
				addedResultField.searchResultsDisplayOrder = startOrder + index
			})
			this.selectedSearchResultFields = []
			this.$emit('update:searchFields', searchFields)
			const searchResultFieldsLength = searchFields.filter(({ includeInSearchResults }) => includeInSearchResults).length
			if (searchResultFieldsLength === this.maxSearchResultsFields) {
				showSnackbar('Max limit of search results fields reached')
			}
		},
		addProfileGridFields () {
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			this.selectedProfileGridFields.forEach(fieldId => {
				const addedProfileField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
				addedProfileField.showInProfilesTable = true
			})
			this.selectedProfileGridFields = []
			this.$emit('update:searchFields', searchFields)
		},
		deleteSearchField (fieldId) {
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			const deletedSearchField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
			searchFields.forEach((searchField, index) => {
				if (searchField.displayOrder > deletedSearchField.displayOrder) {
					searchFields[index].displayOrder -= 1
				}
			})
			deletedSearchField.isSearchableBy = false
			this.$emit('update:searchFields', searchFields)
		},
		deleteSearchResultField (fieldId) {
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			const deletedResultField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
			searchFields.forEach((resultField, index) => {
				if (resultField.searchResultsDisplayOrder > deletedResultField.searchResultsDisplayOrder) {
					searchFields[index].searchResultsDisplayOrder -= 1
				}
			})
			deletedResultField.includeInSearchResults = false
			this.$emit('update:searchFields', searchFields)
		},
		deleteProfileGridField (fieldId) {
			const searchFields = JSON.parse(JSON.stringify(this.searchFields))
			const deletedProfileField = searchFields.find(({ searchFieldId }) => searchFieldId === fieldId)
			deletedProfileField.showInProfilesTable = false
			this.$emit('update:searchFields', searchFields)
		}
	}
}
</script>
