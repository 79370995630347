<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Field Configuration
			</template>
			<template #body>
				<div class="cassie-vertical-md">
					<div
						v-if="userFullPermissions"
						class="d-flex cassie-horizontal-md"
					>
						<Dropdown
							v-model="selectedField"
							:items="mappedAvailableFields"
							custom-sort
							label="Select a field to add"
							style="width: 300px; flex: none;"
						/>
						<PrimaryActionButton
							:disabled="!selectedField"
							@click="addField"
						>
							Add
						</PrimaryActionButton>
					</div>
					<v-simple-table
						dense
						fixed-header
						height="700"
					>
						<template #default>
							<thead>
								<tr>
									<th
										scope="col"
										class="text-left"
									>
										Visible in Contact Details
									</th>
									<th
										scope="col"
										class="text-left"
									>
										Database Field Name
									</th>
									<th
										scope="col"
										class="text-left"
										style="width: 20%;"
									>
										Field Label On Contact Details Screen
									</th>
									<th
										scope="col"
										class="text-left"
									>
										Read Only
									</th>
									<th
										scope="col"
										class="text-left"
										style="width: 10%;"
									>
										Field Type
									</th>
									<th
										scope="col"
										class="text-left"
										style="width: 20%;"
									>
										Allowed Values
									</th>
									<th
										scope="col"
										class="text-left"
									>
										Is Mandatory
									</th>
									<th
										scope="col"
										class="text-left"
									>
										Visible in Overview
									</th>
									<th
										scope="col"
										class="text-left"
									>
										Visible in Create Screen
									</th>
									<th
										scope="col"
										class="text-left"
									>
										Is Mandatory in Create Screen
									</th>
									<th
										scope="col"
										style="width: 70px"
									/>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(contactDetailsField, index) in contactDetailsFields"
									:key="contactDetailsField.fieldName"
								>
									<td class="text-left">
										<v-switch
											:disabled="!userFullPermissions"
											:input-value="contactDetailsField.include"
											dense
											@change="updateContactDetailsFields(index, 'include', $event)"
										/>
									</td>
									<td
										v-show="false"
										class="text-left"
									>
										{{ contactDetailsField.fieldId }}
									</td>
									<td class="text-left">
										{{ contactDetailsField.fieldName }}
									</td>
									<td
										class="text-left"
										style="width: 15%;"
									>
										<TextField
											:disabled="!userFullPermissions"
											:value="contactDetailsField.fieldLabel"
											:rules="{max:100}"
											small
											label="Field label"
											@input="updateContactDetailsFields(index, 'fieldLabel', $event)"
										/>
									</td>
									<td
										v-if="userFullPermissions"
										class="text-left"
									>
										<v-switch
											:disabled="isSpecialField(contactDetailsField.fieldName)"
											:input-value="contactDetailsField.isReadOnly"
											dense
											@change="updateContactDetailsFields(index, 'isReadOnly', $event)"
										/>
									</td>
									<td
										class="text-left"
										style="width: 15%;"
									>
										<Dropdown
											:disabled="isSpecialField(contactDetailsField.fieldName) || !userFullPermissions"
											:value="contactDetailsField.fieldType"
											:items="contactDetailsFieldTypes"
											custom-sort
											label="Field type"
											small
											@input="updateContactDetailsFields(index, 'fieldType', $event)"
										/>
									</td>
									<td
										class="text-left"
										style="width: 15%;"
									>
										<TextField
											:disabled="isSpecialField(contactDetailsField.fieldName) || notRegexOrDropdownFieldType(contactDetailsField.fieldType) || !userFullPermissions"
											:value="contactDetailsField.allowedValues ? contactDetailsField.allowedValues.join(',') : ''"
											:rules="{max:1000}"
											small
											label="Allowed values"
											@input="value => updateContactDetailsFields(index, 'allowedValues', validatedAllowedValuesValue(contactDetailsField.fieldType, value))"
										/>
									</td>
									<td class="text-left">
										<v-switch
											:disabled="isSpecialField(contactDetailsField.fieldName) || !userFullPermissions"
											:input-value="contactDetailsField.isMandatory"
											dense
											@change="updateContactDetailsFields(index, 'isMandatory', $event)"
										/>
									</td>
									<td class="text-left">
										<v-switch
											:disabled="!userFullPermissions"
											:input-value="contactDetailsField.showInOverview"
											dense
											@change="updateContactDetailsFields(index, 'showInOverview', $event)"
										/>
									</td>
									<td class="text-left">
										<v-switch
											:disabled="isSpecialField(contactDetailsField.fieldName) || !userFullPermissions"
											:input-value="contactDetailsField.displayInCreateDataSubjectScreen"
											dense
											@change="updateContactDetailsFields(index, 'displayInCreateDataSubjectScreen', $event)"
										/>
									</td>
									<td class="text-left">
										<v-switch
											:disabled="isSpecialField(contactDetailsField.fieldName) || !userFullPermissions"
											:input-value="contactDetailsField.isMandatoryInCreateDataSubjectScreen"
											dense
											@change="updateContactDetailsFields(index, 'isMandatoryInCreateDataSubjectScreen', $event)"
										/>
									</td>
									<td class="text-left">
										<v-icon
											v-if="userFullPermissions"
											small
											@click="onRemoveClick(contactDetailsField.fieldName)"
										>
											mdi-trash-can
										</v-icon>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</div>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Functionality
			</template>
			<template #body>
				<v-switch
					:disabled="!userFullPermissions"
					:input-value="showPostCodeLookup"
					label="Postcode Lookup Enabled"
					@change="$emit('update:showPostCodeLookup', $event)"
				/>
			</template>
		</SectionCard>
	</div>
</template>

<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'

let timer

const debounce = func => {
	return (...args) => {
		clearTimeout(timer)
		timer = setTimeout(() => { func.apply(this, args) }, 250)
	}
}

const SPECIAL_FIELD_NAME = 'SyrenisID'
const DROPDOWN_FIELD_TYPE = 2

export default {
	components: { PrimaryActionButton, TextField, Dropdown, SectionCard },
	props: {
		contactDetailsFields: {
			type: Array,
			default: () => []
		},
		showPostCodeLookup: {
			type: Boolean,
			default: false
		},
		availableFields: {
			type: Array,
			default: () => []
		},
		userFullPermissions: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			selectedField: null,
			contactDetailsFieldTypes: [
				{ value: 1, text: 'Text Box' },
				{ value: 2, text: 'Dropdown' },
				{ value: 3, text: 'Email' },
				{ value: 4, text: 'Regex' },
				{ value: 5, text: 'Alpha' },
				{ value: 6, text: 'AlphaNum' },
				{ value: 7, text: 'Integer' },
				{ value: 8, text: 'Decimal' }
			].sort((a, b) => a.text.localeCompare(b.text))
		}
	},
	computed: {
		mappedAvailableFields () {
			return this.availableFields
				.filter(({ columnName }) => this.contactDetailsFields.every(({ fieldName }) => columnName.toLowerCase() !== fieldName.toLowerCase()))
				.map(({ columnName }) => ({ value: columnName, text: columnName }))
		}
	},
	methods: {
		updateContactDetailsFields (index, property, value) {
			const contactDetailsFields = JSON.parse(JSON.stringify(this.contactDetailsFields))
			contactDetailsFields[index][property] = value
			const isReadOnlyFieldName = 'isReadOnly'
			const isMandatoryFieldName = 'isMandatory'
			const visibleOnScreenFieldName = 'include'
			if (property === isMandatoryFieldName && value === true) {
				contactDetailsFields[index][isReadOnlyFieldName] = false
			} else if (property === isReadOnlyFieldName && value === true) {
				contactDetailsFields[index][isMandatoryFieldName] = false
			} else if (property === visibleOnScreenFieldName && value === false) {
				contactDetailsFields[index][isMandatoryFieldName] = false
			}

			debounce(() => this.$emit('update:contactDetailsFields', contactDetailsFields))()
		},
		onRemoveClick (fieldName) {
			const contactDetailsFields = this.contactDetailsFields.filter(field => field.fieldName !== fieldName)
			this.$emit('update:contactDetailsFields', contactDetailsFields)
		},
		addField () {
			this.$emit('update:contactDetailsFields', [
				...this.contactDetailsFields,
				{
					fieldName: this.selectedField,
					fieldLabel: '',
					fieldId: 0,
					include: true,
					showInOverview: false,
					isMandatory: false,
					isReadOnly: false,
					displayInCreateDataSubjectScreen: false,
					isMandatoryInCreateDataSubjectScreen: false,
					fieldType: 1,
					allowedValues: []
				}
			])
			this.selectedField = null
		},
		isSpecialField (fieldName) {
			return fieldName.toString().toLowerCase() === SPECIAL_FIELD_NAME.toLowerCase()
		},
		notRegexOrDropdownFieldType (fieldType) {
			const dropdownType = 2
			const regexType = 4
			if (fieldType === dropdownType) return false
			else if (fieldType === regexType) return false
			else return true
		},
		validatedAllowedValuesValue (fieldType, value) {
			if (value === '') return []
			if (fieldType === DROPDOWN_FIELD_TYPE) return value.split(',')
			return [value]
		}
	}
}
</script>
