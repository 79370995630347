var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Preference Configuration ")]},proxy:true},{key:"title-action",fn:function(){return [(_vm.userFullPermissions)?_c('TextButton',{on:{"click":function($event){_vm.showAddGroupModal = {}}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Create Group ")],1):_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('DataSubjectPreferenceExpansionPanels',{attrs:{"config-mode":"","preference-groups":_vm.parentGroups},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('th',{staticClass:"text-left",staticStyle:{"calc(width":"100% - 600px)"}},[_vm._v(" "+_vm._s(_vm._f("useLabels")('Channel'))+" Name ")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"200px"}},[_vm._v(" Can Be Added ")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"200px"}},[_vm._v(" Hide if no Preference Held ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Manage Channel ")])]},proxy:true},(_vm.userFullPermissions)?{key:"parent-group-header",fn:function(ref){
var parentGroupName = ref.parentGroupName;
var parentGroupIndex = ref.parentGroupIndex;
return [(parentGroupIndex !== 0)?_c('IconButton',{attrs:{"small-icon":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.moveParentGroupUp(parentGroupIndex)}}},[_vm._v(" mdi-arrow-up ")]):_vm._e(),(parentGroupIndex !== _vm.parentGroups.length - 1)?_c('IconButton',{attrs:{"small-icon":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.moveParentGroupDown(parentGroupIndex)}}},[_vm._v(" mdi-arrow-down ")]):_vm._e(),_c('TextButton',{attrs:{"color":"white","small":""},on:{"click":function($event){$event.stopPropagation();_vm.showAddSubGroupModal = { parentGroupIndex: parentGroupIndex, parentGroupName: parentGroupName }}}},[_c('v-icon',{staticStyle:{"transform":"unset"},attrs:{"color":"white"}},[_vm._v(" mdi-plus ")]),_vm._v(" Create Subgroup ")],1),_c('TextButton',{attrs:{"color":"white","small":""},on:{"click":function($event){$event.stopPropagation();_vm.showEditGroupModal = { parentGroupName: parentGroupName, parentGroupIndex: parentGroupIndex }}}},[_c('v-icon',{staticStyle:{"transform":"unset"},attrs:{"color":"white"}},[_vm._v(" mdi-pencil ")]),_vm._v(" Edit Group ")],1),_c('TextButton',{attrs:{"color":"white","small":""},on:{"click":function($event){$event.stopPropagation();_vm.parentGroupToDelete = { parentGroupName: parentGroupName, parentGroupIndex: parentGroupIndex }}}},[_c('v-icon',{staticStyle:{"transform":"unset"},attrs:{"color":"white"}},[_vm._v(" mdi-trash-can ")]),_vm._v(" Delete Group ")],1)]}}:null,(_vm.userFullPermissions)?{key:"sub-group-header",fn:function(ref){
var subGroupName = ref.subGroupName;
var subGroupIndex = ref.subGroupIndex;
var parentGroupIndex = ref.parentGroupIndex;
var parentGroupName = ref.parentGroupName;
var channels = ref.channels;
return [(subGroupIndex !== 0)?_c('IconButton',{attrs:{"color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.moveSubGroupUp(parentGroupIndex, subGroupIndex)}}},[_vm._v(" mdi-arrow-up ")]):_vm._e(),(subGroupIndex !== _vm.parentGroups[parentGroupIndex].subGroups.length - 1)?_c('IconButton',{attrs:{"color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.moveSubGroupDown(parentGroupIndex, subGroupIndex)}}},[_vm._v(" mdi-arrow-down ")]):_vm._e(),_c('TextButton',{attrs:{"color":"white","small":""},on:{"click":function($event){$event.stopPropagation();_vm.showEditSubGroupModal = { subGroupName: subGroupName, subGroupIndex: subGroupIndex, parentGroupIndex: parentGroupIndex, parentGroupName: parentGroupName, channels: channels }}}},[_c('v-icon',{staticStyle:{"transform":"unset"},attrs:{"color":"white"}},[_vm._v(" mdi-pencil ")]),_vm._v(" Edit Subgroup ")],1),_c('TextButton',{attrs:{"color":"white","small":""},on:{"click":function($event){$event.stopPropagation();_vm.subGroupToDelete = { subGroupName: subGroupName, subGroupIndex: subGroupIndex, parentGroupIndex: parentGroupIndex }}}},[_c('v-icon',{staticStyle:{"transform":"unset"},attrs:{"color":"white"}},[_vm._v(" mdi-trash-can ")]),_vm._v(" Delete Subgroup ")],1)]}}:null,{key:"rows",fn:function(ref){
var channels = ref.channels;
var parentGroupIndex = ref.parentGroupIndex;
var subGroupIndex = ref.subGroupIndex;
return _vm._l((channels),function(channel,channelIndex){return _c('tr',{key:channel.channelId},[_c('td',[_vm._v(" "+_vm._s(channel.channelName)+" ")]),_c('td',[_c('v-switch',{staticClass:"ma-0",attrs:{"disabled":!_vm.userFullPermissions,"input-value":channel.canBeAdded,"dense":"","hide-details":""},on:{"change":function($event){return _vm.setChannelProperty(channelIndex, parentGroupIndex, subGroupIndex, 'canBeAdded', $event)}}})],1),_c('td',[_c('v-switch',{staticClass:"ma-0",attrs:{"disabled":!_vm.userFullPermissions,"input-value":channel.hideNoPref,"dense":"","hide-details":""},on:{"change":function($event){return _vm.setChannelProperty(channelIndex, parentGroupIndex, subGroupIndex, 'hideNoPref', $event)}}})],1),_c('td',[(_vm.userFullPermissions)?_c('IconButton',{attrs:{"tooltip-text":"Manage Extended Preferences and Required StandDak Fields"},on:{"click":function($event){$event.stopPropagation();_vm.showManageChannelModal = { parentGroupIndex: parentGroupIndex, subGroupIndex: subGroupIndex, channel: channel, channelIndex: channelIndex }}}},[_vm._v(" mdi-pencil ")]):_vm._e()],1)])})}}],null,true)}),(_vm.showAddGroupModal || _vm.showEditGroupModal)?_c('CsPortalConfigPreferencesParentGroupModal',_vm._b({on:{"close":function () {
				_vm.showAddGroupModal = null
				_vm.showEditGroupModal = null
			},"add":_vm.addGroup,"edit":_vm.editGroup}},'CsPortalConfigPreferencesParentGroupModal',_vm.showEditGroupModal,false)):_vm._e(),(_vm.showEditSubGroupModal || _vm.showAddSubGroupModal)?_c('CsPortalConfigPreferencesSubGroupModal',_vm._b({attrs:{"all-selected-channels":_vm.allSelectedChannels,"move":_vm.move},on:{"close":function () {
				_vm.showEditSubGroupModal = null
				_vm.showAddSubGroupModal = null
			},"add":_vm.addSubGroup,"edit":_vm.editSubGroup}},'CsPortalConfigPreferencesSubGroupModal',_vm.showEditSubGroupModal || _vm.showAddSubGroupModal,false)):_vm._e(),(_vm.showManageChannelModal)?_c('CsPortalConfigPreferencesChannelModal',_vm._b({attrs:{"available-fields":_vm.availableFields},on:{"close":function () {
				_vm.showManageChannelModal = null
			},"edit":_vm.editChannel}},'CsPortalConfigPreferencesChannelModal',_vm.showManageChannelModal,false)):_vm._e(),(_vm.parentGroupToDelete)?_c('ConfirmDeleteModal',{attrs:{"entity-name":_vm.parentGroupToDelete.parentGroupName,"entity-type":"Group"},on:{"close":function($event){_vm.parentGroupToDelete = null},"delete":_vm.deleteGroup}}):_vm._e(),(_vm.subGroupToDelete)?_c('ConfirmDeleteModal',{attrs:{"entity-name":_vm.subGroupToDelete.subGroupName,"entity-type":"Subgroup"},on:{"close":function($event){_vm.subGroupToDelete = null},"delete":_vm.deleteSubGroup}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }